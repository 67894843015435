* {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.loadingSpinner {
  width: 48px;
  height: 48px;
  border: 5px solid #47BEB9;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: loadingSpinnerRotation 1s linear infinite;
  margin: auto;
}


@keyframes loadingSpinnerRotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body {
  background-color: #FBFBFB;
}

.paddingTop {
  padding-top: 8rem;
}

.heroSvg {
  max-width: 400px;
}

.mainTitle {
  color: #242424;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 70.4px;
  /* 110% */
  letter-spacing: -1.92px;
}

.borderDiv {
  border-radius: 12px;
  box-shadow: 1px 0 0 0 #000,
    0 1px 0 0 #000,
    0px 0px 0 0 #000,
    1px 0 0 0 #000 inset,
    0 1px 0 0 #000 inset
}

.secondTitle {
  color: #242424;

  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 45.4px;
  /* 120% */
  letter-spacing: -0.42px;
}

.paragraphStyle {
  color: #505050;


  font-size: 18.438px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  /* 173.559% */
  letter-spacing: 0.4px;
}

.blackBtn {
  border-radius: 6px;
  background: #242424;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  border: none;
  color: #FFF;
  text-align: center;

  font-size: 13.234px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px;
  /* 148.099% */
  letter-spacing: 0.56px;
}

.swiper-wrapper svg {
  width: 100%;
  max-height: 400px;
}

.greenBlobBg {
  background-image: url('./assets/images/greenBg.svg');
  background-size: cover;
  background-position: center;
}


.greenBlobLeft {
  background-image: url('./assets/images/greenLeftBg.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: center;
}

.greyLabel {
  border-radius: 4px;
  background: #F3F5F9;
  color: #505050;

  font-style: normal;
  width: min-content;
  font-weight: 400;
  line-height: 24px;
}

.listNumber {
  color: #505050;

  font-size: 22.688px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.6px;
  /* 148.099% */
  letter-spacing: -0.24px;
}

.smallParagraph {
  color: #505050;

  font-size: 16.734px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.8px;
  /* 172.101% */
  letter-spacing: 0.36px;
}

.greenBg3 {
  border-radius: 32px;
  background: linear-gradient(180deg, #F4FBFD 0%, rgba(246, 254, 233, 0.00) 100%);
}

.secondLabel {
  border-radius: 34px;
  background: #E4F5F4;
  width: 140px;
  margin: auto;
  padding: 10px;
}

.secondLabel span {
  color: #160647;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.62px;
  /* 133% */
  text-transform: capitalize;
}

.greenBtn {
  border-radius: 99px;
  background: #47BEB9;
  padding: 0.75rem 1.5rem;
  color: #FFF;


  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: none;
}

.borderDiv2 {
  border-radius: 12px;
  box-shadow: 1px 0 0 0 #505050,
    0 1px 0 0 #505050,
    0px 0px 0 0 #505050,
    1px 0 0 0 #505050 inset,
    0 1px 0 0 #505050 inset;
  position: relative;

}

.cornerLabel {
  color: #505050;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  width: 51.66px;
  height: 36px;
  padding: 6px 9.8px 6px 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(243, 245, 249, 0.60);

}

.imgPlaceholder {
  aspect-ratio: 16/9;
}

.dateSpan {
  color: #000;

  font-size: 12.797px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  opacity: 0.6;
}

.faqTitle {
  color: #212123;


  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footerStyle {
  border-radius: 12px 12px 0px 0px;
  background: #242424;
}

.footerStyle span {
  color: #FFF;


  font-size: 13.453px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  opacity: 0.6;
}

.fixedChatHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

}

.chatHeaderBorder {
  background-color: #FFF;
}


.chatContainer {
  height: calc(100dvh - 210px);
  overflow-y: auto;
  position: relative;
}

.sendMessageInput {
  border: 1px solid #CDD9E5;
  border-radius: 4px;
  width: 100%;
}

.sendMessageInput input::placeholder {
  color: #CDD9E5 !important;
  font-weight: 400 !important;
}

.sendMessageInput input {
  box-shadow: none !important;
}

.clientMessage {
  color: #fff;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(180deg, #47BEB9 0%, #59D45E 100%);
  padding: 1rem 1.5rem;
  border-radius: 16px 0px 16px 16px;
  max-width: 500px;
}

.botMessage {
  background: #F0F4F8;
  border-radius: 0px 16px 16px 16px;
  color: #000;
}



.whiteScreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 150px);
  background-color: white;
  z-index: 10;
}

.activeSvg path {
  stroke: #49C0B5;
}

.chatContainer::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.chatContainer::-webkit-scrollbar-track {
  border-radius: 67px;
  background: #F0F0F0;

}

/* Handle */
.chatContainer::-webkit-scrollbar-thumb {
  border-radius: 67px;
  background: #49BFB350;
}

/* Handle on hover */
.chatContainer::-webkit-scrollbar-thumb:hover {
  background: #49BFB3;
}


.insurancesList {
  height: 400px;
  overflow-y: auto;

}

.insurancesList::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.insurancesList::-webkit-scrollbar-track {
  border-radius: 67px;
  background: #F0F0F0;

}

/* Handle */
.insurancesList::-webkit-scrollbar-thumb {
  border-radius: 67px;
  background: #C7E8CD;
}

/* Handle on hover */
.insurancesList::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #47BEB9 0%, #59D45E 100%);
}

.chatGreenBlob {
  background-position-y: bottom;
}

.chatWrapper {
  max-width: 1000px !important;
}

.modalBackground {
  backdrop-filter: blur(41px);
  background-color: rgba(29, 32, 34, 0.40);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: grid;
  place-items: center;
}

.chatModalContent {
  border-radius: 8px;
  background: #FFF;
  max-width: 450px;
  width: calc(100% - 1.5rem);
}

.inputStyle {
  border-radius: 4px !important;
  border: 1px solid rgba(151, 151, 151, 0.27) !important;
  padding: 10px 6px 10px 10px !important;
  font-size: 14px !important;
}

.inputStyle::placeholder {
  color: #5B5B5B !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.modalBtn {
  width: 100%;
  color: #fff;
  font-weight: 600;
  padding: 10px;
  border-radius: 4px;
  background: #00C098;
  border: 1px solid #00C098;
}


.plzDropdown {
  position: absolute;
  top: 3.5rem;
  background-color: #fff;
  max-height: 300px;
  padding: 0.75rem;
  width: 100%;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
  overflow-y: auto;
  z-index: 100;
  text-align: left;
}

.selectOptionStyle {
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.emptyOption {
  font-size: 12px !important;
}


.container1 input {
  border: 1px solid #ddd;
}

.checkmark {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  font-weight: 400;
  cursor: pointer;
}

.cardTitle {
  font-size: 18px;
  font-weight: 600;
}

.container1 input:checked~.checkmark {
  border: 1px solid #59D45E;
  transition: all 0.2s ease-in-out;
}

.checkmark {
  display: block;
}

.container1 input:checked~.checkmark:after {
  display: block;
}

.checkmarkColorAuto {
  border: 4px solid #fff;
}

.multipleQuestionDiv {
  background-color: #fff;
  border: 1px solid #56D071;
  border-radius: 12px;
  padding: 10px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


@media (max-width: 1399.98px) {}

@media (max-width: 1199.98px) {
  .mainTitle {
    font-size: 57px;
  }

  .secondTitle {
    font-size: 32px;
    line-height: 37px;
  }

  .clientMessage {
    max-width: 500px;
  }

}

@media (max-width: 991.98px) {
  .secondTitle {
    font-size: 30px;
    line-height: 32px;
  }

  .clientMessage {
    max-width: 500px;
  }

}

@media (max-width: 767.98px) {
  .mainTitle {
    font-size: 50px;
  }

  .secondTitle {
    font-size: 22px;
    line-height: 30px;
  }

  .clientMessage {
    max-width: 450px;
  }

}


@media (max-width: 575.98px) {
  .mainTitle {
    font-size: 35px;
    line-height: 45px;
  }

  .clientMessage {
    max-width: 67vw;
    padding: 0.75rem 1rem;
  }

  .heroSvg {
    max-width: 350px;
  }

}

.service-line {
  background: linear-gradient(90deg, #FFFFFF 0%, #E3F5F3 32.28%, #d8f5f2 100%);
  height: 3px;
  width: 0px;
  margin-top: 10px;
  border-radius: 9px;
}

.service-item {
  padding: 20px;
  padding-right: 0px;
  padding-bottom: 0px;
  width: 100%;
}

.service-item h4 {
  color: #898989;
  font-size: 18px !important;
  line-height: 31.2px;
  letter-spacing: -0.24px;
  font-weight: 700;
}

/* LoadingAnimation.css */
.container {
  position: relative;
}

.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #007bff;
  position: absolute;
}

.icon1 {
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.icon2 {
  top: 50%;
  left: 90%;
  transform: translate(-50%, -50%);
}

.line {
  position: absolute;
  height: 2px;
  background-color: #007bff;
  width: 0;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  animation: loadAnimation 3s linear forwards;
}

@keyframes loadAnimation {
  100% {
    width: 80%;
    left: 90%;
  }
}


.service-item .service-text {
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  overflow: hidden;
  height: 0;
}

.service-item .service-text p {
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
  padding-right: 20px;
  color: #5E5E5E;
  line-height: 25.86px;
  font-size: 16px;
  font-weight: 400;
}


/* Hamburger menu */
.bm-overlay{
  background: transparent !important;
  max-width: 414px !important;
}

.bm-menu{
  display: flex;
  justify-content: center;
  align-items: center;
}

.bm-item-list{
  padding-top: 200px;
}

.bm-menu-wrap{
  position: fixed !important;
  background: white !important;
}

.menu-item{
  padding: 15px;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  color:#42464D;
  text-align: center;
  border: none !important;
}