.stepsContainer {
    height: calc(100dvh - 120px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
    max-width: 630px;
    margin: auto;
    padding-inline: 1.5rem;
}

.insuranceBorderDiv input:checked~.insurancePriceCheckmark{
    border: "2px solid #49BFA9"
}

.stepsGreyBorder {
    border-radius: 8px;
    width: 100%;
    margin-top: 1rem;
    margin-inline: auto;
}

.chatFormLabel {
    color: #1F1F1F;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* The container */
.ChatFormCheckmarkContainer {
    display: block;
    position: relative;
    cursor: pointer;
    height: 52px;
    width: 500px;
}

/* When the checkbox is checked, add a blue background */
/* .checkmarkChatForm {
    height: 52px;
    width: 500px;
    border-radius: 42px;
    background: #FFF;
    text-align: center;
    margin-top: 5px;
    color: #050808;
    font-size: 14px;
} */

.insurancePriceCheckmark {
    border-radius: 42px;
    border: 1px solid #fff;
}

.checkmarkChatForm svg {
    max-width: 80px;
    height: 50px;
}

.ChatFormCheckmarkContainer input:checked {
    border: 2px solid #49BFA9;
}

.ChatFormCheckmarkContainer input:checked {
    border: 2px solid #49BFA9;
}



.chatFormInput {
    border-radius: 6px !important;
    border: 1px solid #DDEBE8 !important;
    background: #FFF !important;
    margin-top: 5px;
}

.chatFormInput::placeholder {
    color: rgba(5, 8, 8, 0.20) !important;
    font-size: 14px !important;
}

.chatFormInputWrapper {
    border-radius: 6px !important;
    border: 1px solid #DDEBE8 !important;
    background: #FFF !important;
}

.plzWrapper {
    height: 100%;
    border-right: 1px solid #DDEBE8;
    display: grid;
    place-items: center;
    color: #050808;
    font-size: 13px;
}

.addChildBtn {
    border-radius: 6px;
    background: #00C098;
    border: none;
    width: 100%;
    padding: 0.75rem;
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
}

.tabLine {
    border-radius: 73px;
    background: rgba(73, 191, 169, 0.21);
    height: 3px;
    width: 100%;
    margin-top: 0.5rem;
}

/* .insuranceBorderDiv {
    border-radius: 6px;
    background: #FFF;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
} */

.bigInsuranceImg {
    height:75px;
    margin: auto;
    width: auto;
}

.bigInsurancePrice {
    color: #212529;
    font-size: 40px;
    font-weight: 600;
    line-height: 1;
}

.bigInsurancePriceCHF {
    color: #212529;
    font-size: 25px;
    font-weight: 600;
    line-height: 19.5px;
}

.insuranceDetails {
    border-radius: 88px;
    border: 1px solid #DDEBE8;
    background: #F4FCF9;
    padding: 15px;
}

.insuranceDetailsText {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.28px;
}

.whiteInsuranceDetails {
    border-radius: 22px;
    border: 1px solid #DDEBE8;
    background: #FFF;
    padding: 12px 19px;
}

.smallGreyLabel {
    color: rgba(32, 32, 32, 0.36);
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.28px;
    text-align: center;
}


.backBtn {
    background: #fff;
    color: #00C098;
}

.insuranceImg {
    max-width: 120px;
    width: 100%;
    height: auto;
}

.chatFormTitle {
    font-size: 14px !important;
}


.filterBtn {
    padding: 0.5rem 0.5rem;
    border-radius: 30px;
    border: 1px solid #C8ECE5;
}

.activeFilter {
    background: #00C098;
    border: 1px solid #00C098;
}

.activeFilter .chatFormLabel {
    color: #fff;

}

.divideLine {
    color: #DDEBE8;
    opacity: 1;
    margin-left: -2rem !important;
    width: calc(100% + 4rem);
}

.selectedInsuranceWrapper {
    background-color: #03C199;
    border-radius: 0px 8px 8px 8px;
}

.insuranceTitle {
    font-size: 22px;
    font-weight: 700;
}

.insurancePrice {
    font-size: 25px;
    font-weight: 700;
}


.dot-typing {
    position: relative;
    left: -9987px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #47BEB9;
    color: #47BEB9;
    box-shadow: 9975px 0 0 0 #47BEB9, 9987px 0 0 0 #47BEB9, 10000px 0 0 0 #47BEB9;
    animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
    0% {
        box-shadow: 9975px 0 0 0 #47BEB9, 9987px 0 0 0 #47BEB9, 10000px 0 0 0 #47BEB9;
    }

    16.667% {
        box-shadow: 9975px -10px 0 0 #47BEB9, 9987px 0 0 0 #47BEB9, 10000px 0 0 0 #47BEB9;
    }

    33.333% {
        box-shadow: 9975px 0 0 0 #47BEB9, 9987px 0 0 0 #47BEB9, 10000px 0 0 0 #47BEB9;
    }

    50% {
        box-shadow: 9975px 0 0 0 #47BEB9, 9987px -10px 0 0 #47BEB9, 10000px 0 0 0 #47BEB9;
    }

    66.667% {
        box-shadow: 9975px 0 0 0 #47BEB9, 9987px 0 0 0 #47BEB9, 10000px 0 0 0 #47BEB9;
    }

    83.333% {
        box-shadow: 9975px 0 0 0 #47BEB9, 9987px 0 0 0 #47BEB9, 10000px -10px 0 0 #47BEB9;
    }

    100% {
        box-shadow: 9975px 0 0 0 #47BEB9, 9987px 0 0 0 #47BEB9, 10000px 0 0 0 #47BEB9;
    }
}

@media (max-width: 1399.98px) {}

@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {}


@media (max-width: 575.98px) {
    .checkmarkChatForm {
        padding: 0.75rem;
    }

    .insuranceImg {
        max-width: 100px;
        height: auto;
    }

    .stepsGreyBorder {
        padding: 2rem 1rem;
    }

    .checkmarkChatForm {
        flex-direction: column !important;
    }

    .bigInsuranceImg {
        height: 75px;
    }

    .stepsContainer {
        padding-inline: 1rem;
    }

    .chatFormTitle {
        font-size: 13px !important;
    }

    .divideLine {
        margin-left: -1rem !important;
        width: calc(100% + 2rem);
    }
}